import { useState } from "react"
import styled from "styled-components"

import { Switch } from "@material-ui/core"

import { TFlagOverride } from "src/components/Flags/FlagContext"
import { FlagsAuth } from "src/components/Flags/FlagsAuth"
import { checkFlagsAuth } from "src/components/Flags/flagsAuthUtil"
import { useFlagProvider } from "src/components/Flags/useFlagProvider"
import { FeatureFlag } from "src/constants/featureFlags"
import { useAppData } from "src/context/useAppData"
import {
  getImpersonateActive,
  impersonateStorage,
} from "src/data/auth/authStorage"
import { DocumentHead } from "src/router/DocumentHead"
import { Routes } from "src/router/routes"
import { BackButton } from "src/ui/BackButton/BackButton"
import { MButton } from "src/ui/Button/MButton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { ITab } from "src/ui/Tabs/Tab"
import { TabPanel } from "src/ui/Tabs/TabPanel"
import { Tabs } from "src/ui/Tabs/Tabs"

// This is just an enforced internal reminder so we don't care if you find this in a pen-test or whatever.
const rule = `"I will not talk about /flags-club"`

interface IFeatureToggle {
  label: string
  flag: FeatureFlag
  description?: string
  disabled?: boolean
  hidden?: boolean
  reloadOnToggle?: boolean
}

const detailedFeatures: IFeatureToggle[] = [
  { label: "Show flags link in sidenav", flag: FeatureFlag.SHOW_FLAGS_LINK },
  { label: "Guest import", flag: FeatureFlag.GUEST_FILE_IMPORT },
  {
    label: "Show invoice breakdown in billing page",
    flag: FeatureFlag.SHOW_INVOICE_BREAKDOWN,
  },
  {
    label: "Show plan renewal",
    flag: FeatureFlag.SHOW_PLAN_RENEWAL,
    description: `Show plan renewal on billing page`,
  },
]

const devFeatures: IFeatureToggle[] = [
  {
    label: "Debug",
    flag: FeatureFlag.DEBUG,
    description: "Debug mode stuff",
  },
  {
    label: "Alpha",
    flag: FeatureFlag.ALPHA,
    description: "Features under development",
  },
  {
    label: "Beta",
    flag: FeatureFlag.BETA,
    description: "Features that are ready for testing",
  },
  {
    label: "Device install",
    flag: FeatureFlag.INSTALL_DEVICE,
    description: `Enables installing device in webapp as well as
    communicating with device via USB cable. NOTE: Requires Blink-based
    browser, e.g., Chrome.`,
    disabled: !navigator.serial,
  },
  {
    label: "Show React Query devtools",
    flag: FeatureFlag.REACT_QUERY_DEVTOOLS,
    description: `Toggle loading of react-query devtools.`,
    reloadOnToggle: true,
  },
  {
    label: "Debug sudden logouts",
    flag: FeatureFlag.LOGOUT_DEBUGGER,
    description: `Launches debugger before logging out`,
  },
]

const loggingConfig: IFeatureToggle[] = [
  {
    label: "Debug logger",
    flag: FeatureFlag.LOGGING_DEBUG,
    description: `Enable if you want to see the debug log output`,
    // reload necessary since the logger is statically set on app init:
    reloadOnToggle: true,
  },
  {
    label: "Default storage logger",
    flag: FeatureFlag.LOGGING_STORAGE_DEFAULT,
    description: `Enable if you want to see the default log output for every
    set/get/clear invocation.`,
    // reload necessary since the default logger is statically set on app init:
    reloadOnToggle: true,
  },
  {
    label: "Router logger",
    flag: FeatureFlag.LOGGING_ROUTER,
    description: `Enable if you want to see the default log output for every
    call to \`useRouter.navigate\`.`,
    // reload necessary since the default logger is statically set on app init:
    reloadOnToggle: true,
  },
  {
    label: "Analytics logger",
    flag: FeatureFlag.LOGGING_ANALYTICS,
    description: `Enable if you want to log analytics requests`,
    // reload necessary since the default logger is statically set on app init:
    reloadOnToggle: true,
  },
  {
    label: "Sentry CaptureConsole logger",
    flag: FeatureFlag.LOGGING_SENTRY_CAPTURE_CONSOLE,
    description: `Enable if you want Sentry to capture all console logs; this
    can be useful for environments where normal logging is not sufficient, e.g.,
    the iOS WebView.`,
    // reload necessary since the Sentry conf is statically set on app init:
    reloadOnToggle: true,
  },
]

const NAMED_FEATURES = [
  ...devFeatures,
  ...detailedFeatures,
  ...loggingConfig,
].map((feature) => feature.flag)

// Get remaining unhandled feature flags and give them a default label
const unnamedFeatures: IFeatureToggle[] = [
  ...Object.values(FeatureFlag)
    .filter((flag) => !NAMED_FEATURES.find((named) => named === flag))
    .map((flag) => ({ flag, label: flag })),
]

const features: IFeatureToggle[] = [...detailedFeatures, ...unnamedFeatures]

export function FlagManager() {
  const { getOverride, ...flagContext } = useFlagProvider()
  const [hasFlagAuth, setHasFlagAuth] = useState(checkFlagsAuth())

  function handleFlagSwitch(f: IFeatureToggle, checked: boolean) {
    flagContext.setFeature(f.flag, checked)
    f.reloadOnToggle && window.location.reload()
  }

  if (!hasFlagAuth) {
    return <FlagsAuth onSaveAuth={() => setHasFlagAuth(true)} reminder={rule} />
  }

  const tabs: ITab[] = [
    {
      id: "features",
      label: "Features",
      view: (
        <FlagsBox>
          {features.map((f) => (
            <FlagRow
              key={f.flag}
              feature={f}
              checked={flagContext.isEnabled(f.flag)}
              onChange={handleFlagSwitch}
              override={getOverride(f.flag)}
            />
          ))}
        </FlagsBox>
      ),
    },
    {
      id: "dev-flags",
      label: "Dev",
      view: (
        <FlagsBox>
          {devFeatures.map((f) => (
            <FlagRow
              key={f.flag}
              feature={f}
              checked={flagContext.isEnabled(f.flag)}
              onChange={handleFlagSwitch}
              override={getOverride(f.flag)}
            />
          ))}

          <ImpersonateToggleButton />
        </FlagsBox>
      ),
    },
    {
      id: "logging",
      label: "Logging",
      view: (
        <FlagsBox>
          {loggingConfig.map((f) => (
            <FlagRow
              key={f.flag}
              feature={f}
              checked={flagContext.isEnabled(f.flag)}
              onChange={handleFlagSwitch}
              override={getOverride(f.flag)}
            />
          ))}
        </FlagsBox>
      ),
    },
  ]

  return (
    <Box>
      <DocumentHead title="Flags" />
      <BackButton to={Routes.Dashboard.location()}>Back</BackButton>
      <MText variant="heading1">Flags</MText>

      <Tabs labels={tabs}>
        {tabs.map((tab) => (
          <TabPanel key={tab.label}>{tab.view}</TabPanel>
        ))}
      </Tabs>
    </Box>
  )
}

function ImpersonateToggleButton() {
  const { user } = useAppData()
  const impersonating = getImpersonateActive()

  return (
    <div>
      {user?.user_id && (
        <MButton
          variant="subtle"
          onClick={() => {
            if (!impersonating) {
              impersonateStorage.set({
                expires_at: Date.now() + 3600,
                user_id: user?.user_id,
              })
            } else {
              impersonateStorage.clear()
            }
            window.location.reload()
          }}
        >
          {impersonating ? "Disable" : "Enable"} simulated impersonation
        </MButton>
      )}
    </div>
  )
}

function FlagRow({
  feature: f,
  checked,
  onChange,
  override,
}: {
  feature: IFeatureToggle
  checked: boolean
  onChange: (f: IFeatureToggle, checked: boolean) => void
  override: TFlagOverride
}) {
  if (f.hidden) {
    return null
  }

  return (
    <div style={{ display: "contents" }}>
      <ToggleTitle>
        <Label>{f.label}</Label>

        {f.description && (
          <MText variant="bodyS" color="secondary">
            {f.description}
          </MText>
        )}

        <MText
          variant="bodyS"
          color="secondary"
        >{`${window.location.origin}?${f.flag}=enabled`}</MText>

        {override && (
          <MText variant="bodyS" color="secondary">
            {override.reason}
          </MText>
        )}
      </ToggleTitle>

      <Switch
        checked={override?.enforced ? (override?.value ?? checked) : checked}
        onChange={(e) => onChange(f, Boolean(e.target.checked))}
        disabled={!!f.disabled || override?.enforced != null}
      />
    </div>
  )
}

const Box = styled.div`
  max-width: 65ch;
  margin: 0 auto;
  padding: ${spacing.L};
`

const FlagsBox = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 2rem;
  margin: 2rem 0;
  max-width: 65ch;
  padding-bottom: 1rem;
`

const Label = styled.div`
  &::first-letter {
    text-transform: capitalize;
  }
`

const ToggleTitle = styled.div``
